.slick-dots.slick-thumb {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* .slick-dots .slick-thumb li {
  width: 6px;
  height: 6px;
  margin: 0 3px;
  border-radius: 999px;
  transition: all 0.5s ease-in-out;
  will-change: width;
  background-color: black;

} */

.slick-dots.slick-thumb li {
  background-color: #5ca7d8;
  width: 24px;
  height: 4px;
}

.slick-dots.slick-thumb li.slick-active {
  background-color: white;
  width: 24px;
  height: 4px;
}