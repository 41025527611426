@import '../../../scss/index.scss';

.header {
  background-image: linear-gradient(90.17deg, #338FCC 23.65%, #4299cf 91.59%);
  height: auto;
}
.header__inner {
  @extend .row;
  @include media-breakpoint-down(lg) {
    justify-content: space-between;
  }
}
.header__logo {
  @include make-col(1);
  background-color: $color-white;
  max-width: 48px;
  position: relative;
  min-height: 100%;
  z-index: 20;
  @include media-breakpoint-down(md) {
    @include make-col(2);
  }
  img {
    display: inline-block;
    width: 36px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 25;
  }
}
.nav {
  @include make-col();
  @include media-breakpoint-down(lg) {
    display: none;
  }
}
.nav__items {
  display: flex;
  justify-content: flex-end;
  padding: 0;
  margin: 0;
  width: 100%;
}
.nav__item {
  align-self: center;

  
  a {
    @include text(400, 15px, 110%);
    color: $color-white;
    display: flex;
    width: max-content;
    font-family: 'Segoe UI Bold';
    padding-top: 24px;
    padding-left: 10px;
    padding-bottom: 24px;
    padding-right: 10px;

    @media screen and (max-width: 1440px) {
      font-size: calc(10px + (15 - 10) * ((100vw - 576px) / (1440 - 576)));
     }
    &:hover {
      background-color: $color-blue;
      cursor: pointer;
    }
  }
}
.button__address {
  @include button($color-white, #168EDC, 10px, 700, 64px, 130%, 175px);
  border: none;
  cursor: pointer;
  font-family: 'Segoe UI Bold';
  text-transform: uppercase;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    display: inline-block;
    font-family: 'Segoe UI Bold';
    text-align: left;
  }
  &:hover {
    color: #168EDC;
  }
}
.button__phone {
  @include button($color-white, $color-text-blue, 14px, 700, 64px, 106.5%, 175px);
  border: none;
  color: #168EDC;
  cursor: pointer;
  margin: 0 0 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .button__phone_icon {
    display: inline-block;
    margin: -2px 9px 0 0;
  }

  &:hover {
    color: #168EDC;
  }
}
.button__phone_text {
  display: inline-block;
  font-family: 'Segoe UI Bold';
  font-weight: 700;
  margin: 0 0 5px 0;
  
}
.button__burger {
  display: none;
  @include media-breakpoint-down(sm) {
    @include make-col();
    margin-right: 40px;
  }
  @include media-breakpoint-down(lg) {
    @include make-col(1);
    background-color: transparent;
    display: block;
    border: none;
    margin-top: 15px;
    margin-bottom: 15px;
  }
}