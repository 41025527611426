@import '../../../../scss/mixins.scss';
@import '../../../../scss/variables.scss';

.request {
  background: linear-gradient(103.19deg, #5EAEE3 0%, #2F8CCA 100%);
  margin-top: 75px;
  padding: 70px 0;
  @media screen and (max-width: 576px) {
    background: $color-white;
    padding: 0 10px 70px 10px;
  }
}
.request__inner {
  max-width: 485px;
  margin: 0 auto;
}
.request__description {
  @include text(400, 21px, 105%);
  color: $color-white;
  font-family: 'Segoe UI';
  margin: 0 0 10px 0;
  text-align: center;
  @media screen and (max-width: 576px) {
    color: #2B81BA;
  }
  @media (max-width: 1147px) {
    font-size : calc(15px + (21 - 15) * ((100vw - 375px) / (1147 - 375)));
   }
}
.request__title {
  @include text(400, 34px, 160%);
  color: $color-white;
  font-family: 'Open Sans', sans-serif;
  margin: 0 0 15px 0;
  text-align: center;
  @media screen and (max-width: 576px) {
    color: #2B81BA;
  }
  @media (max-width: 1147px) {
    font-size : calc(24px + (34 - 24) * ((100vw - 375px) / (1147 - 375)));
   }
}
.request__subtitle {
  @include text(600, 20px, 165%);
  color: $color-white;
  font-family: 'Segoe UI';
  font-weight: normal;
  letter-spacing: 1px;
  margin: 0 0 14px 0;
  text-align: center;
  @media screen and (max-width: 576px) {
    display: none;
    font-size: 0;
  }
}
.request__form {
  display: flex;
  align-items: center;
  flex-direction: column;
  input {
    border: none;
    box-shadow: 0px 0px 10px rgba(8, 63, 99, 0.16);
    display: inline-block;
    width: 323px;
    padding: 15px 25px;
    margin: 0 0 14px 0;
    &::placeholder {
      @include text(400, 14px, 105%);
      color: #168EDC;
      font-family: 'Segoe UI';
      opacity: 0.5;
    }
    &:focus {
      outline: none;
    }
  }
  button {
    @include button($color-white, #168EDC, 18px, 700, 48px, 105%, 323px);
    background-color: transparent;
    background-color: $color-white;
    border: none;
    box-shadow: 0px 0px 10px rgba(8, 63, 99, 0.16);
    cursor: pointer;
    display: inline-block;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    @media screen and (max-width: 576px) {
      background: linear-gradient(103.19deg, #5EAEE3 0%, #2F8CCA 100%);
      color: $color-white;
    }
  }
}
.request__check {
  text-align: center;
  margin: 20px 0 0 0 ;
  input {
    box-shadow: 0px 0px 10px rgba(8, 63, 99, 0.16);
  }
  label {
    @include text(400, 15px, 105%);
    color: $color-white;
    margin: 0 0 0 9px;
    @media screen and (max-width: 576px) {
      color: #2B81BA;
      font-size : calc(11px + (15 - 11) * ((100vw - 320px) / (576 - 320)));
    }
  }
}
.request__agree {
  @include text(400, 15px, 185%);
  color: $color-white;
  font-family: 'Segoe UI';
  text-decoration: underline;
  @media screen and (max-width: 576px) {
    color: #2B81BA;
  }
}