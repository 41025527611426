@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.footer {
  background-image: linear-gradient(103.19deg, #5EAEE3 0%, #2F8CCA 100%);
  font-family: 'Segoe UI Bold';
  height: 88px;
  overflow: hidden;
}
.footer__inner {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    padding: 0 23px 0 0;
  }
}
.container {
  max-width: 1147px;
  margin: 0 auto;
}
.footer__logo {
  background-color: $color-white;
  flex: 0 0 68px;
  position: relative;
  min-height: 88px;
}
.footer__logo_image {
  display: inline-block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 46px;
}
.nav {
  display: flex;
  padding: 0;
  margin: 0;
  @media screen and (max-width: 768px) {
    display: none;
  }
}
.nav__items {
  display: flex;
  align-items: stretch;
  padding: 0;
  margin: 0;
}
.nav__item {
  @include text(bold, 15px, 110%);
  align-content: stretch;
  align-items: center;
  display: block;
  height: 100%;

  a {
    display: inline-block;
    color: $color-white;
    padding: 36px 15px;
  }
  &:hover {
    background-color: $color-blue;
    cursor: pointer;
  }
  @media screen and (max-width: 1294px) {
    font-size: calc(11px + (15 - 11) * ((100vw - 1024px) / (1294 - 1024)));
  }
}
.footer__buttons {
  display: flex;
  flex-direction: column;
  align-self: center;
  @media screen and (max-width: 768px)  {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 60%;
  }
  .button__address {
    @include button(transparent, $color-white, 10px, bold, 20px, 130%, 140px);
    border: none;
    cursor: pointer;
    text-align: start;
    text-transform: uppercase;
    margin: 0 0 15px 0;
    span {
      display: inline-block;
      text-align: left;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .button__phone {
    @include button(transparent, $color-white, 14px, bold, 20px, 106.5%, 140px);
    border: none;
    cursor: pointer;
    text-align: start;
    @media screen and (max-width: 768px) {
      align-self: center;
    }
    span {
      display: inline-block;
      text-align: left;
    }
    img {
      margin: 0 11px 0 0;
    }
  }
  .button__toggle {
    background-color: transparent;
    border: none;
    display: none;
    @media screen and (max-width: 768px) {
      display: block;
    }
  }
  button {
    display: block;
  }
}