@import '../../../../scss/mixins.scss';
@import '../../../../scss/variables.scss';

.burger__inner {
  display: none;

}

.burger__active {
  background: linear-gradient(90.17deg, rgba(32, 99, 145, 0.9) 23.65%, rgba(19, 90, 135, 0.9) 91.59%);
  box-shadow: 0px 1px 17px rgba(0, 0, 0, 0.08);
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 100;
  transition: all .2s;

  .burger__list {
    display: flex;
    flex-direction: column;
    margin: 0 0 25px 0;
    transition: all .4s;

    li a {
      @include text(700, 18px, 110%);
      background: rgba(255, 255, 255, 0.1);
      color: #fff;
      cursor: pointer;
      display: block;
      padding: 15px 32px;
      margin: 0 0 10px 0;
    }
  }

}

.burger__socials {
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    display: inline-block;
    margin: 0 0 0 20px;

    &:nth-child(1) {
      margin: 0;
    }
  }

  img {
    display: inline-block;
    height: 28px;
    width: 32px;
  }
}