$grid-columns: 12;
$grid-gutter-width: 20px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$container-max-widths: (
  xs: 375px,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1146px,
);

@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';

@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";

@import "../../node_modules/bootstrap/scss/mixins/lists";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/mixins/container";
@import "../../node_modules/bootstrap/scss/mixins/grid";
@import "../../node_modules/bootstrap/scss/mixins/utilities";

@mixin make-max-width-container($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $max-width;
    }
  }
}