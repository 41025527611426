@import '../../../../../scss/index.scss';

.hero {
  $thumbnail-bg: $body-bg;
  background: image-set( url('../../../../../assets/images/home/home-bg.jpg') 1x,
  url('../../../../../assets/images/home/home-bg.webp') 1x) no-repeat 85% center;
  background-size: cover;
  height: 80vh;
  max-width: 100%;
  padding: 0;
  margin: 0;
  position: relative;
  margin-bottom: 85px;
  @media screen and (max-width: 1147px) {
    margin-bottom: calc(-7px + (85 - 0) * ((100vw - 375px) / (1147 - 375)));
  }
  @media screen and (max-width:576px) {
    min-height: 100vh;
  }
}
.hero__wrapper {
  @extend .row;
  justify-content: end;
  
}
.hero__inner { 
  @include make-col(7);
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    margin-top: 12%;
  }
  @include media-breakpoint-down(lg) {
    @include make-col();
    margin-top: 24%;
  }

  @media screen and (max-width: 768px) {
    margin-top: 30%;
  }
  @media screen and (max-width: 576px) {
    margin-top: 40%;
  }
}
.hero__title {
  @include text(700, 68px, 73px);
  color: #fff;
  font-family: 'Oswald', sans-serif;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 0 20px 0;
  @media screen and (max-width: 1147px) {
    font-size: calc(56px + (68 - 56) * ((100vw - 375px) / (1147 - 375)));
  }
}
.hero__description {
  display: flex;
  font-family: 'Segoe UI';
  flex-direction: column;
}
.hero__description_size {
  @include text(400, 26px, 110%);
  background-color: #E73A3A;
  color: $color-white;
  display: inline-block;
  justify-content: center;
  padding: 5px 17px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
}
.hero__description_text {
  @include text(600, 15px, 106%);
  display: inline-block;
  color: $color-white;
  letter-spacing: 1px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 270px;
}
.hero__contact_btn {
  @include button($color-white, #168EDC, 18px, 700, 43px, 110%, 294px);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 21px rgba(255, 255, 255, 0.3);
  cursor: pointer;
  font-family: 'Oswald', sans-serif;
  margin: 0 auto;
  text-transform: uppercase;
  &:hover {
    color: #168EDC;
  }
}
.hero__scroll__button {
  @apply bg-black;
  background: #FFFFFF;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.14);
  bottom: -32px;
  cursor: pointer;
  position: absolute;
  left: 50%;
  width: 42px;
  height: 64px;


  img {
    @apply top-10;
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: mymove 1s infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate;

    @keyframes mymove {
      from {top: 20px;}
    }
    
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}