.hero__social_group {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 300px;
  left: 0;
  width: 40px;
  height: 196px;
  background: #5EAEE3;
  z-index: 1000;
  a {
    display: inline-block;
    margin: 24px 0 0 0;
    &:nth-child(1) {
      margin: 0;
    }
  }
  @media screen and (max-width: 576px) {
    display: none;
  }
}